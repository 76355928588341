import React from 'react';
import styled from 'styled-components';
import config from '@p14/config';
import data from '@p14/texts/documents';
import logs from '@p14/texts/logs.json';

export const Root = styled.div`
  margin: 8px;
  font-size: 14px;
  font-family: tahoma;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const Td = styled.td`
  border: 1px solid #000;
  padding: 1em;
  margin: 0;
`;

export const Logs = ({tr, data}) => (
  <Root>
    <h2 style={{textAlign: 'center'}}>Электронный журнал учета операций, выполненных с помощью программного обеспечения и технологических средств ведения официального сайта застройщика, позволяющие обеспечивать учет всех действий в отношении информации на официальном сайте застройщика, подтверждающих размещение соответствующей информации</h2>
    <Table>
      <thead>
        <tr>
          <Td style={{fontWeight: 'bold'}}>Вид размещаемой информации</Td>
          <Td style={{fontWeight: 'bold'}}>Дата опубликования на сайте</Td>
          <Td style={{fontWeight: 'bold'}}>Лицо, разместившее информацию</Td>
        </tr>
      </thead>
      <tbody>
        {data.allStrapiLogs.edges.map(({node}, index) => (
          <tr key={node.id}>
            <Td><a href={node.doc.publicURL} target="_blank">{node.title}</a></Td>
            <Td colSpan={node.responsible ? 1 : 2}>{node.date}</Td>
            {node.responsible ? <Td>{node.responsible}</Td> : null}
          </tr>
        ))}
      </tbody>
    </Table>
  </Root>
);

export default Logs;
