import {graphql} from 'gatsby';

import Logs from '../../screens/documents/Logs';
export default Logs;

export const query = graphql`
  query {
    allStrapiLogs {
      edges {
        node {
          id
          title
          date
          responsible
          doc {
            publicURL
          }
        }
      }
    }
  }
`;
